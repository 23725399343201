<template>
  <div class="order">
    <van-cell-group inset>
      <van-form ref="myform">
        <van-cell>
          <van-field
            v-model="account"
            name="手机号"
            label="手机号"
            placeholder="请输入手机号"
            :rules="rules"
          >
            <template #button>
              <van-button
                v-if="showbtn"
                round
                size="small"
                type="primary"
                :disabled="attcode"
                @click="sendSms"
                >获取验证码</van-button
              >
              <van-button
                class="tsbtn"
                style="margin: 0 0.5em"
                round
                size="small"
                v-else
                >{{ code_ts }}</van-button
              >
            </template>
          </van-field>
        </van-cell>
        <van-cell>
          <van-field
            v-model="sms"
            center
            clearable
            label="验证码"
            placeholder="请输入短信验证码"
          >
          </van-field>
        </van-cell>
        <van-cell>
          <div style="margin: 16px">
            <van-button round block type="info" @click="submit()">查询</van-button>
          </div>
        </van-cell>
      </van-form>
    </van-cell-group>
    <div :class="orderClass" @click="click">我的订单</div>
  </div>
</template>
<script>
import { api_sms_send, api_sms_check, api_authoirze, api_userinfo } from "@/api/api.js";
import { getQueryVariable } from "@/util/utils.js";
export default {
  data() {
    return {
      account: "",
      sms: "",
      supplierId: "",
      showbtn: true,
      attcode: true,
      code_ts: "获取验证码",
      sec: 60,
      orderClass: "my_order",
      location: "",
      rules: [
        {
          name: "account",
          required: true,
          trigger: "onBlur/onChange",
        },
        {
          validator: this.check,
          trigger: "change",
        },
      ],
    };
  },
  created() {
    this.location = window.location.href;
    this.supplierId = this.$route.query.supplierId;
  },

  methods: {
    getCode() {
      const params = {
        redirect_url: window.location.href,
      };
      api_authoirze(params)
        .then((res) => {
          if (res.data.code == 0) {
            window.location.href = res.data.data;
          } else {
            console.log(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getToken() {
      const code = getQueryVariable("code");
      if (code) {
        this.Cook.set("code", code);
        const params = {
          code: code,
        };
        api_userinfo(params)
          .then((res) => {
            if (res.data.code == 0) {
              sessionStorage.setItem(
                "accessToken",
                "yh" + " " + res.data.data.accessToken
              );
              let state_en = window.localStorage.getItem("state_en");
              if (state_en) {
                this.$router.push({
                  path: "/order",
                  query: {
                    supplierId: this.supplierId,
                  },
                });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.getCode();
      }
    },
    check(value) {
      if (!/^[1][3-9][\d]{9}/.test(value)) {
        this.$toast.fail("请输入正确格式的手机号码");
        return false;
      }
    },
    //发送验证码
    sendSms() {
      var timer = setInterval(() => {
        this.sec = this.sec - 1;
        this.code_ts = this.sec + "S后重试";
        this.showbtn = false;
        if (this.sec === 0) {
          clearInterval(timer);
          this.sec = 60;
          this.code_ts = this.sec + "S后重试";
          this.showbtn = true;
        }
      }, 1000);
      const params = {
        phone: this.account,
        sourceUrl: this.location,
      };
      api_sms_send(params).then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data);
        }
      });
    },
    submit() {
      this.getSmsCode();
    },
    getSmsCode() {
      if (this.sms != "") {
        const params = {
          phone: this.account,
          code: this.sms,
        };
        api_sms_check(params).then((res) => {
          if (res.data.code == 0) {
            sessionStorage.setItem("code", res.data.data);
            this.$router.push({
              path: "/checkOrder",
              query: {
                supplierId: this.supplierId,
              },
            });
          }
        });
      } else {
        this.$toast.fail("手机号或验证码不能为空");
      }
    },
    click() {
      this.orderClass = "click_order";
      localStorage.setItem("state_en", true);
      this.getToken();
    },
  },
  watch: {
    account: function (value) {
      var reg = /\b1[3456789]\d{9}\b/g;
      let val = reg.test(value);
      if (val) {
        this.attcode = false;
      } else {
        this.attcode = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f4f6f9;
  ::v-deep .van-field__label {
    margin-right: 0;
    width: 4em;
  }
  .my_order {
    color: rgb(139, 137, 137);
    margin-top: 0.5em;
    font-size: 1.5em;
    margin-left: 18em;
    border-bottom: 1px solid;
  }
  .click_order {
    color: blue;
    margin-top: 0.5em;
    font-size: 1.5em;
    margin-left: 18em;
    border-bottom: 1px blue solid;
  }
}
</style>
